
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useCheckAuth } from '../hooks';
import { CheckingAuth } from '../ui/components/CheckingAuth';
import { AuthRoutes } from '../auth/routes/AuthRoutes';
import { UIRoutes } from '../ui/routes/UIRoutes';

export const AppRouter = () => {
  const {status}  = useCheckAuth();

  if(status === 'checking'){
    return <CheckingAuth />
  }

  return (
    <Routes>
      {
        (status === 'authenticated')
        ? <Route path="/*" element={<UIRoutes />}/> 
        : <Route path="/auth/*" element={<AuthRoutes />}/>
      }

      <Route path="/*" element={<Navigate to="/auth/start" />} /> 
    </Routes>
  )
}
