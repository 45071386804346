import { useNavigate } from 'react-router-dom'
import './MenuButton.css'

export const MenuButtonComponent = ({ type='button', path='', hidden= false, hiddenCheck=false, classButton='' }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(path)
  }
    return (
      <button type={ type } onClick={ onClick } hidden={hidden}  className={classButton}>
        <img src="/assets/img/Icon_Correct_1.png" alt="Icon correct"  hidden={hiddenCheck} className='icon-check' />
      </button>
    )
  }
  

