import React from 'react';
import './ComidasInicio.css';
import {AudioButton} from './AudioButton'
import { useNavigate } from 'react-router-dom';

const ComidasPInicio = () => {
    const pathAudio = '/assets/Audios/comidas/comidas_presentacion.mp3';
    const navigate = useNavigate();

    const onClick = () => {
        navigate('/comidasCooler')
    }

    return (
        <>
        <div className="row">
            <div className='btn-audio-comidas'>
                <AudioButton pathAudio={pathAudio} />
            </div>
        </div>
        <div className="row">
            <div className='generalContainderComidas'>
                <div className="containerInicioComidas">
                    <div className="image-containerComidas">
                        
                    </div>
                    <div className="buttons-containerComidas">
                        <div className="top-buttonComidas"></div>
                        <button className="bottom-buttonComidas" onClick={onClick}></button>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default ComidasPInicio;
