import { getActivitiesInformation, getTestInformation, registerActivity, registerActivityDone, registerTest } from "../../api/providers";
import {setActiveImage, setActivitiesInformation, setDoAllActivities, setDoTest, setError, setInitalState, setScoreFirstActivity, setScoreSecondActivity, setStoreImageComunicationSelected, setStoreImageSelected, setTestCorrect, setTestReset, setTestScore } from "./uiSlice";

export const startCheckActivities = (id ) => {
    return async (dispatch) => {
        const {ok, data } = await getActivitiesInformation(id);

        if(!ok) return dispatch(setError());

        const { respuesta = [] } = data;
        const result = respuesta.filter((element) => {
            return element.estatus === 'P';
        });

        const doAllActivities = result.length === 0;
        dispatch(setDoAllActivities(doAllActivities));
    }
}

export const startGetActivitiesInformation = (id) => {
    return async (dispatch) => {
        const { ok ,data} = await getActivitiesInformation(id);

        if(!ok) return dispatch(setError());

        const { respuesta = [] } = data;

        dispatch(setActivitiesInformation(respuesta));
    }
}

export const startCheckTestCorrect = (correct, answersSelected) => {
    return async (dispatch) => {
        dispatch(setTestCorrect({correct, answersSelected}))
    }
}

export const startRegisterTest= (json, errorMessage) => {
    return async (dispatch) =>{

        if(errorMessage != null){
            dispatch(setError(null))
        }

        const {ok, data } = await registerTest(json);
        if(!ok) return dispatch(setError(data.mensaje));

        dispatch(setTestScore(json.puntos))
    }

}


export const startCheckTestDone = (id) => {
    return async (dispatch) => {
        const {ok, data } = await getTestInformation(id);

        if(!ok) return dispatch(setError());

        
        const { respuesta = [] } = data;

        const jsonDoTest = { "testBegin": respuesta[0].estatus === "C", "testEnd": respuesta[1].estatus === "C"} 
        
        dispatch(setDoTest(jsonDoTest));
    }
}

export const startSetTestScore = (score) => {
    return async (dispatch) => {
        dispatch(setTestScore(score))
    }
}

export const startActiveImage = (id, name, path, parrillaNumber) => {
    return async (dispatch) => {
        dispatch(setActiveImage({id, name, path, parrillaNumber}))
    }
} 

export const startStoreImageSelected = (image) => {
    return async (dispatch) => {
        dispatch(setStoreImageSelected(image))
    }
}

export const startInitalState = () => {
    return async (dispatch) => {
        dispatch(setInitalState())
    }
}

export const startRegisterActivity = (json, errorMessage, activity = 1) => {
    return async (dispatch) =>{

        if(errorMessage !== null){
            dispatch(setError(null))
        }

        const {ok, data } = await registerActivity(json);

        if(!ok) return dispatch(setError(data.message));

        if(activity === 1){
            dispatch(setScoreFirstActivity(json.puntos))
        }else if (activity === 2){
            dispatch(setScoreSecondActivity(json.puntos))
        }
    }

}

export const startStoreImageComunicationSelected = (image) => {
    return async (dispatch) => {
        dispatch(setStoreImageComunicationSelected(image))
    }
}

export const startRegisterActivityDone = (json, errorMessage) => {
    return async (dispatch) =>{

        if(errorMessage !== null){
            dispatch(setError(null))
        }

        const {ok, data } = await registerActivityDone(json);

        if(!ok) return dispatch(setError(data.message));
    }
}

export const startResetTestAnswers = () => {
    return async (dispatch) =>{
        dispatch(setTestReset());
    }
}