import React from 'react';
import './MisMomentosCoolerResult.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
// import {AudioButton} from './AudioButton'

export const MisMomentosCoolerResult = () => {
    const navigate = useNavigate();
    const {firstActivityScore, secondActivityScore} = useSelector( state => state.ui);

    const finalScore = firstActivityScore + secondActivityScore;

    const onClick = () => {
        navigate('/');    
    }

    useEffect(() => {
        window.location.hash="No-back-button";
       window.location.hash="Again-No-back-button" //chrome
       window.onhashchange=function(){window.location.hash="No-back-button";}
    }, [])
    return (
        <>
        <div className='generalContainderMisMomentosCoolerResult'>
            {/* <div>
                <AudioButton />
            </div> */}
            <div className="containerInicioMisMomentosCoolerResult">
                <div className="imagecontainerMisMomentosCoolerResult">
                    <p className='scoreCoolerMisMomentos'>{finalScore}</p>
                    <button className="buttonMisMomentosCoolerResult" onClick={onClick}></button>
                </div>
            </div>
        </div>
        </>
    );
}