import React from 'react';
import './HidratacionInicio.css';
import {AudioButton} from './AudioButton'
import { useNavigate } from 'react-router-dom';

const HidratacionPInicio = () => {
    const pathAudio = '/assets/Audios/hidratacion/hidratacion_presentacion.mp3';
    const navigate = useNavigate();

    const onClick = () => {
        navigate('/hidratacionCooler')
    }
    return (
        <>
        <div className="row">
            <div className='btn-audio-hidratacion'>
                <AudioButton pathAudio={pathAudio} />
            </div>
        </div>
        <div className="row">
            <div className='generalContainderHidratacion'>
                <div className="containerInicioHidratacion">
                    <div className="image-containerHidratacion">
                        
                    </div>
                    <div className="buttons-containerHidratacion">
                        <div className="top-buttonHidratacion"></div>
                        <button className="bottom-buttonHidratacion" onClick={onClick}></button>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default HidratacionPInicio;
