import { Navigate, Route, Routes } from "react-router-dom"
import { UIPage } from "../pages/UIPage"
import { MisMomentosCooler, MisMomentos, MisMomentosCoolerComunicacion,
  Hidratacion, HidratacionCooler, HidratacionCoolerComunicacion,
  HidratacionRack, HidratacionRackComunicacion,
  Desayuno, DesayunoCooler, DesayunoCoolerComunicacion,
  DesayunoRack, DesayunoRackComunicacion,
  Comidas, ComidasCooler, ComidasCoolerComunicacion, ComidasRack,
  ComidasRackComunicacion, ComidasTortillero, 
  ComidasTortilleroComunicacion } from "../pages"
import { 
  ComidasCoolerResult, ComidasRackResult, ComidasTortilleroResult, 
  DesayunoCoolerResult, DesayunoRackResult, 
  HidratacionCoolerResult,HidratacionRackResult,
  MisMomentosCoolerResult} from "../components";

export const UIRoutes = () => {
  return (
    <Routes>
        <Route path="/" element={ <UIPage />} />
        <Route path="misMomentosCooler" element={ <MisMomentosCooler />} />
        <Route path="misMomentosCoolerPublicidad" element={ <MisMomentosCoolerComunicacion />} />
        <Route path="comidas" element={ <Comidas />} />
        <Route path="comidasCooler" element={ <ComidasCooler />} />
        <Route path="comidasCoolerComunicacion" element={ <ComidasCoolerComunicacion />} />
        <Route path="comidasCoolerResultado" element={ <ComidasCoolerResult />} />
        <Route path="comidasRack" element={ <ComidasRack />} />
        <Route path="comidasRackComunicacion" element={ <ComidasRackComunicacion />} />
        <Route path="comidasRackResultado" element={ <ComidasRackResult />} />
        <Route path="comidasTortillero" element={ <ComidasTortillero />} />
        <Route path="comidasTortilleroComunicacion" element={ <ComidasTortilleroComunicacion />} />
        <Route path="comidasTortilleroResult" element={ <ComidasTortilleroResult />} />
        <Route path="mismomentos" element={ <MisMomentos />} />
        <Route path="hidratacion" element={ <Hidratacion />} />
        <Route path="hidratacionCooler" element={ <HidratacionCooler />} />
        <Route path="hidratacionCoolerComunicacion" element={ <HidratacionCoolerComunicacion />} />
        <Route path="hidratacionCoolerResultado" element={ <HidratacionCoolerResult />} />
        <Route path="hidratacionRack" element={ <HidratacionRack />} />
        <Route path="hidratacionRackComunicacion" element={ <HidratacionRackComunicacion />} />
        <Route path="hidratacionRackResultado" element={ <HidratacionRackResult />} />
        <Route path="desayuno" element={ <Desayuno />} />
        <Route path="desayunoCooler" element={ <DesayunoCooler />} />
        <Route path="desayunoCoolerComunicacion" element={ <DesayunoCoolerComunicacion />} />
        <Route path="desayunoCoolerResultado" element={ <DesayunoCoolerResult />} />
        <Route path="desayunoRack" element={ <DesayunoRack />} />
        <Route path="desayunoRackComunicacion" element={ <DesayunoRackComunicacion />} />
        <Route path="desayunoRackResultado" element={ <DesayunoRackResult />} />
        <Route path="puntuacionFinal" element={ <MisMomentosCoolerResult />} />
        <Route path="HidratacionCoolerPuntuacionFinal" element={ <HidratacionCoolerResult />} />

        <Route path="/*" element={ <Navigate to="/" />} />
    </Routes>
  )
}
