import './AudioButton.css'
import React, {useState} from 'react';

let audio = new Audio()

export const AudioButton = ({pathAudio = ""}) => {

  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.createRef();

  const playAudio = () => {
    setIsPlaying(true);
    audio = new Audio(pathAudio)
    audio.play()
    audio.onended = () => {
      setIsPlaying(false);
    }
  };
  return (
    <>
      <audio ref={audioRef} src={pathAudio}></audio>
      <button
        className={`audioButton  ${+ (isPlaying) ? 'filter-brightness' : ''}`}
        disabled={isPlaying}
        onClick={playAudio}
      >
      </button>
    </>
  );
}