import './MainMenu.css'
import { AudioButton } from '../components/AudioButton'
import { MenuButtonComponent } from '../components/MenuButton'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startGetActivitiesInformation, startInitalState } from '../../store/ui/thunks'
import { UILayout } from '../layout/UILayout'
import { startLogout } from '../../store/auth/thunks'

export const MainMenu = () => {
  const pathAudio = '/assets/Audios/menu/audio_menu.mp3';
  const dispatch = useDispatch()

  const { activitiesInformation = [], id } = useSelector( state => state.ui);

  let hideComidas = true;
  let hideDesayunos = true;
  let hideHidratacion = true;
  let hideMisMomentos = true;

  const onLogout = () =>{
    dispatch(startLogout())
  }

  useEffect(() => {
    dispatch(startInitalState());
    dispatch(startGetActivitiesInformation(id))
  }, [dispatch, id])
  return (
    <UILayout>
      <div className="row">
            <div className="col-md-6 col">
                <div className='btn-audio-menu'>
                  <AudioButton pathAudio={pathAudio} />
                </div>
            </div>
            <div className="col-md-6 col">
                <div className="float-right">
                  <button onClick={onLogout}  type='button' className='start-btn-registrar btn-menu-logout'></button>
                </div>
            </div>
      </div>
      <div className="row  my-moments-margin-top">
        <div className='menuGeneralContainer'>
          <div className='menuElementsContainer'>
            <div className='menu-img'></div>
            <div className='menuButtonsContainer'>
            {(activitiesInformation) && activitiesInformation.map(item => {
                  switch (item.nombre) {
                    case "Comidas":
                      if(item.estatus === "T" ){
                        hideComidas = false;
                      }
                      break;
                    case "Mis momentos":
                      if(item.estatus === "T" ){
                        hideMisMomentos = false;
                      }
                      break;
                    case "Hidratación":
                      if(item.estatus === "T" ){
                        hideHidratacion = false;
                      }
                      break;
                    case "Desayunos":
                      if(item.estatus === "T" ){
                        hideDesayunos = false;
                      }
                      break;
                    default:
                      break;
                  }
              })}
              <MenuButtonComponent type='submit'  classButton='menuBtnComidas' hiddenCheck={hideComidas} path='comidas' />
              <MenuButtonComponent type='submit'  classButton='menuBtnMisMomentos' hiddenCheck={hideMisMomentos} path='mismomentos' />
              <MenuButtonComponent type='submit'  classButton='menuBtnHidratacion' hiddenCheck={hideHidratacion} path='hidratacion' />
              <MenuButtonComponent type='submit'  classButton='menuBtnDesayuno' hiddenCheck={hideDesayunos} path='desayuno' />
              <MenuButtonComponent type='submit'  classButton='menuBtnSocializacion' hidden={true} path='socializacion' />
            </div>
          </div>     
        </div>
      </div>
    </UILayout>
  )
}


